import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import * as _ from 'lodash-es';

const settingsStructure: ModelSchema = new ModelSchema({
    isOnboardingEnabled: Structures.boolean(false),
    isOpenBadgeActive: Structures.object({default: true, gar: false}),
    navLinks: Structures.object({
        default: [{
            path: 'my-profile',
            keyTranslate: 'achievement.my_profile',
        },
            {
                path: 'my-city',
                keyTranslate: 'achievement.my_city',
            },
            {
                path: 'leaderboard',
                keyTranslate: 'achievement.leaderboard',
            }
        ],
        gar: [{
            path: 'my-profile',
            keyTranslate: 'achievement.my_profile',
        },
            {
                path: 'my-city',
                keyTranslate: 'achievement.my_city',
            }
        ]
    }),
    alwaysUseObs: Structures.boolean(false),
    listenRootFilterSelectedElem: Structures.boolean(false),
});

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private readonly privateSettings: { [key: string]: any };

    constructor() {
        this.privateSettings = settingsStructure.filterModel(modulesSettings.achievements);
    }

    public isOnboardingEnabled(): boolean {
        return _.get(this.privateSettings, 'isOnboardingEnabled', false);
    }

    public navLinks(): INavlink {
        return _.get(this.privateSettings, 'navLinks', {
            default: [{
                path: 'my-profile',
                keyTranslate: 'achievement.my_profile',
            },
                {
                    path: 'my-city',
                    keyTranslate: 'achievement.my_city',
                },
                {
                    path: 'leaderboard',
                    keyTranslate: 'achievement.leaderboard',
                }
            ],
            gar: [{
                path: 'my-profile',
                keyTranslate: 'achievement.my_profile',
            },
                {
                    path: 'my-city',
                    keyTranslate: 'achievement.my_city',
                }
            ]
        });
    }

    public isOpenBadgeActive(): { default: boolean, gar: boolean } {
        return _.get(this.privateSettings, 'isOpenBadgeActive', {default: true, gar: false});
    }

    public getAlwaysUseObs(): boolean {
        return _.get(this.privateSettings, 'alwaysUseObs', false);
    }

    public getListenRootFilterSelectedElem(): boolean {
        return _.get(this.privateSettings, 'listenRootFilterSelectedElem', false);
    }
}

export interface INavlink {
    default: {
        path: string,
        keyTranslate: string
    }[],
    gar: {
        path: string,
        keyTranslate: string
    }[]
}
