import {Injectable} from '@angular/core';
import {GroupsManagementServicesInstantiatorService} from '@modules/groups-management';
import {SvgIconList} from './themes/amazon_python/svgIconList';
import {AssignmentServicesInstantiatorService} from '@modules/assignation';
import {ActivitiesServicesInstantiatorService} from '@modules/activities';
import {AuthenticationServicesInstantiatorService} from "@modules/authentication";
import {LicensingService} from '@modules/licensing/core/licensing.service';
import {GamificationService} from '@modules/gamification/core/gamification.service';
import {ConfigurationService} from '@modules/configuration';
import {NewsService} from 'fuse-core/news/news.service';
import {SnackbarNewsService} from 'fuse-core/news/snackbar/snackbar-news.service';
import {GraphHumanumService} from '@modules/graph-humanum';
import {GraphInclusiveService} from '@modules/graph-inclusive';
import {GraphBayardService} from '@modules/graph-bayard';
import {GlobalAuthorizationService} from './global-authorization.service';
import {AchievementServicesInstantiatorService} from "@modules/achievement";
import {OnboardingService as GamificationOnboardingService} from '@modules/gamification/core/onboarding/onboarding.service';
import {AccountManagementServicesInstantiatorService} from "@modules/account-management";
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {LrsService} from '@modules/lrs';
import {FormDialogService} from "fuse-core/components/form-dialog/form-dialog.service";


@Injectable()
export class ServiceInstantiator {

    constructor(
        private basicPageService: BasicPageService,
        private formDialogService: FormDialogService,
        private SvgIconList: SvgIconList,
        private licensingService: LicensingService,
        private gamificationService: GamificationService,
        private configurationService: ConfigurationService,
        private newsService: NewsService,
        private snackbarNewsService: SnackbarNewsService,
        private graphHumanumService: GraphHumanumService,
        private graphInclusiveService: GraphInclusiveService,
        private graphBayardService: GraphBayardService,
        private globalAuthorizationService: GlobalAuthorizationService,
        private gamificationOnboardingService: GamificationOnboardingService,
        private lrsService: LrsService,
        private accountManagementServices: AccountManagementServicesInstantiatorService,
        private achievementServices: AchievementServicesInstantiatorService,
        private activitiesServices: ActivitiesServicesInstantiatorService,
        private assignmentServices: AssignmentServicesInstantiatorService,
        private authenticationServices: AuthenticationServicesInstantiatorService,
        private groupsManagementServices: GroupsManagementServicesInstantiatorService,
    ) {
    }

}
