import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-my-profile-popup',
    templateUrl: './my-profile-popup.component.html',
    styleUrls: ['./my-profile-popup.component.scss']
})
export class MyProfilePopupComponent implements OnInit {
    constructor(
        private router: Router,
        private snackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
    }

    dismiss($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.snackBar.dismiss();
    }

}