import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarDataInjectedInterface} from 'fuse-core/news/snackbar/snack-bar-data-injected.interface';

@Component({
  selector: 'app-leaderboard-pop-up',
  templateUrl: './leaderboard-pop-up.component.html',
  styleUrls: ['./leaderboard-pop-up.component.scss']
})
export class LeaderboardPopUpComponent implements OnInit {

    constructor(private snackBar: MatSnackBar,
                @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarDataInjectedInterface) {
    }

    ngOnInit(): void {
    }

    onClick(): void {
        this.snackBar.dismiss();
    }


}